
function App() {
   return (
      <div>
         <div style={{maxWidth:500, padding:20, margin:'0px auto'}}>
            <img style={{width:'100%'}} src='/images/kkradio.png' alt='KaribKonnect' />
         </div>

         <div style={{maxWidth:500, padding:5, margin:'0px auto', textAlign:'center'}}>
            <audio controls>
               <source src="https://s7.myradiostream.com/60168/listen.mp3" type="audio/mpeg"/>               
               Your browser does not support the audio element.
            </audio>
         </div>

         <div style={{maxWidth:500, margin:'30px auto', textAlign:'center'}}>
             <a target='_blank' rel='noreferrer' href='https://www.facebook.com/KaribKonnect'><img src='/images/facebook.png' alt='facebook' style={{width:'30px', margin:4, border:0}} /></a>
             <a target='_blank' rel='noreferrer' href='https://www.youtube.com/channel/UCnXjZtr0CxBQLpcMox8bzvA'><img src='/images/youtube.png' alt='youtube' style={{width:'30px', margin:4, border:0}} /></a>
             <a target='_blank' rel='noreferrer' href='https://www.twitter.com/KaribKonnect'><img src='/images/twitter.png' alt='twitter' style={{width:'30px', margin:4, border:0}} /></a>
             <a target='_blank' rel='noreferrer' href='https://www.instagram.com/karibkonnectradio'><img src='/images/instagram.png' alt='instagram' style={{width:'30px', margin:4, border:0}} /></a>
             <a target='_blank' rel='noreferrer' href='mailto:KaribKonnect@gmail.com'><img src='/images/gmail.png' alt='KaribKonnect@gmail.com' style={{width:'30px', margin:4, border:0}} /></a>
         </div>
      </div>
   )
}
export default App
